import React, { createContext, useReducer, useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../services/api';
import useLogging from "../hooks/useLogging";
import { getLoginUrl, getSignupUrl } from '../utils/constants';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

const initialState = {
	user: null,
	loading: true,
	authCallbackInProgress: false,
};

function authReducer(state, action) {
	switch (action.type) {
		case 'SET_USER':
			return { ...state, user: action.payload, loading: false };
		case 'SET_LOADING':
			return { ...state, loading: action.payload };
		case 'SET_AUTH_CALLBACK_PROGRESS':
			return { ...state, authCallbackInProgress: action.payload };
		default:
			return state;
	}
}

export function AuthProvider({ children }) {
	const logger = useLogging("AuthProvider");
	const [state, dispatch] = useReducer(authReducer, initialState);
	const navigate = useNavigate();
	const location = useLocation();
	const isProcessingAuth = useRef(false);

	const clearUserData = useCallback(() => {
		dispatch({ type: 'SET_USER', payload: null });
		localStorage.removeItem('authToken');
		localStorage.removeItem('userData');
		delete api.defaults.headers.common['Authorization'];
	}, [dispatch]);

	const isTokenValid = useCallback((token) => {
		if (!token) return false;
		try {
			const decodedToken = jwtDecode(token);
			return decodedToken.exp * 1000 > Date.now();
		} catch (error) {
			return false;
		}
	}, []);

	const checkUser = useCallback(async () => {
		const token = localStorage.getItem('authToken');
		if (isTokenValid(token)) {
			api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
			const userData = JSON.parse(localStorage.getItem('userData'));
			if (userData) {
				dispatch({ type: 'SET_USER', payload: userData });
				return userData;
			} else {
				try {
					const response = await api.get('/auth/user');
					localStorage.setItem('userData', JSON.stringify(response.data));
					dispatch({ type: 'SET_USER', payload: response.data });
					return response.data;
				} catch (error) {
					console.error("Error fetching user data:", error);
					clearUserData();
					return null;
				}
			}
		} else {
			clearUserData();
			return null;
		}
	}, [dispatch, isTokenValid]);

	const processAuthCallback = useCallback(async (code) => {
		if (isProcessingAuth.current) return;
		isProcessingAuth.current = true;
		dispatch({ type: 'SET_AUTH_CALLBACK_PROGRESS', payload: true });
		try {
			logger.log('Handling auth callback');
			const response = await api.post('/auth/callback', { code });
			const { access_token } = response.data.tokens;
			
			localStorage.setItem('authToken', access_token);
			api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
			
			await checkUser();
			navigate('/dashboard', { replace: true });
		} catch (error) {
			logger.error('Error handling auth callback:', error);
			dispatch({ type: 'SET_USER', payload: null });
			localStorage.removeItem('authToken');
			navigate('/', { replace: true });
		} finally {
			isProcessingAuth.current = false;
			dispatch({ type: 'SET_AUTH_CALLBACK_PROGRESS', payload: false });
		}
	}, [checkUser, navigate, logger]);

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const code = urlParams.get('code');
		if (code && !isProcessingAuth.current) {
			processAuthCallback(code);
		}
	}, [location, processAuthCallback]);

	const login = useCallback(() => {
		window.location.href = getLoginUrl();
	}, []);

	const signup = useCallback(() => {
		window.location.href = getSignupUrl();
	}, []);

	const logout = useCallback(() => {
		clearUserData();
		navigate('/', { replace: true });
	}, [clearUserData, navigate]);

	const value = React.useMemo(() => ({
			...state,
			login,
			signup,
			logout,
			checkUser,
			clearUserData
		}), [state, login, signup, logout, checkUser, clearUserData]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

// Create a custom hook for getting clearUserData
export const useClearUserData = () => {
	const { clearUserData } = useAuth();
	return clearUserData;
};