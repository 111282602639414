import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, Box, useTheme, alpha } from "@mui/material";

const EndInterviewDialog = ({ open, onClose, onConfirm }) => {
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    backgroundColor: alpha(theme.palette.background.paper, 0.9),
                    borderRadius: '8px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(8px)',
                }
            }}
        >
            <DialogTitle sx={{ 
                borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                padding: theme.spacing(3, 4),
            }}>
                <Typography variant="h6" component="span" sx={{ 
                    fontWeight: 'bold',
                    background: theme.palette.primary.main,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                }}>
                    Submit
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ padding: theme.spacing(4) }}>
                <DialogContentText sx={{ 
                    paddingTop: theme.spacing(4),
                    color: alpha(theme.palette.text.primary, 0.8),
                    fontSize: '0.95rem',
                }}>
                    Please confirm that you want to submit the final solution. This action cannot be undone.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ 
                padding: theme.spacing(0, 4, 4),
            }}>
                <Button 
                    onClick={onClose} 
                    sx={{
                        padding: theme.spacing(1, 3),
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.text.secondary, 0.1),
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button 
                    onClick={onConfirm} 
                    variant="contained"
                    sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                        color: theme.palette.primary.main,
                        padding: theme.spacing(1, 3),
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2),
                        }
                    }}
                >
                    Submit and End Interview
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EndInterviewDialog;