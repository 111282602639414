import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getInterviewDetails, startInterview, sendMessage, endInterview } from '../services/api';
import useLogging from './useLogging';
import { parseISO, differenceInSeconds } from "date-fns";

const useInterviewLogic = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const logger = useLogging("InterviewLogic");

    const [sessionId, setSessionId] = useState(searchParams.get('sessionId'));
    const [messages, setMessages] = useState([]);
    const [code, setCode] = useState("");
    const [lastAnalyzedCode, setLastAnalyzedCode] = useState("");
    const [inputMessage, setInputMessage] = useState("");
    const [sessionDetails, setSessionDetails] = useState(null);
    const [timeRemaining, setTimeRemaining] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const [isAnalyzingCode, setIsAnalyzingCode] = useState(false);
    const streamingMessageRef = useRef('');
    const [currentStatus, setCurrentStatus] = useState(null);
    const [isEndDialogOpen, setIsEndDialogOpen] = useState(false);
    const [isInterviewEnded, setIsInterviewEnded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        const fetchDetailsAndStart = async () => {
            try {
                if (!sessionId) {
                    logger.warn("No session ID provided, redirecting to dashboard");
                    navigate("/dashboard");
                    return;
                }

                const details = await getInterviewDetails(sessionId);
                logger.log("Fetched interview details", details);
                setSessionDetails(details.data);

                const endTime = parseISO(details.data.endTime);
                const now = new Date();

                const remainingSeconds = Math.max(0, differenceInSeconds(endTime, now));
                logger.log("Remaining seconds:", remainingSeconds);

                setTimeRemaining(remainingSeconds);

                startInterviewSession(sessionId);
            } catch (error) {
                logger.error("Error fetching interview details or starting interview:", error);
                navigate("/dashboard");
            }
        };

        fetchDetailsAndStart();
    }, [sessionId, navigate]);

    useEffect(() => {
        if (timeRemaining === null) return;

        const timer = setInterval(() => {
            setTimeRemaining((prevTime) => {
                if (prevTime <= 0) {
                    clearInterval(timer);
                    handleTimeExpired();
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [timeRemaining]);

    const startInterviewSession = async (sessionId) => {
        try {
            const response = await startInterview(sessionId);
            logger.log("Interview started", response.data);
            addMessage({ id: Date.now(), text: response.data.prompt, sender: "Byte" });
        } catch (error) {
            logger.error("Error starting interview:", error);
            addMessage({
                id: Date.now(),
                text: "Error starting interview. Please try again.",
                sender: "System",
            });
        }
    };

    const addMessage = (newMessage) => {
        setMessages(prev => [...prev, { ...newMessage, isNew: true }]);
        setTimeout(() => {
            setMessages(prev => prev.map(msg =>
                msg.id === newMessage.id ? { ...msg, isNew: false } : msg
            ));
        }, 50);
    };

    const processMessageStream = useCallback(async (responseLines, initialMessage = null) => {
        let byteMessageAdded = false;

        for (const line of responseLines) {
            await new Promise(resolve => setTimeout(resolve, 10));

            try {
                const data = JSON.parse(line);
                
                if (data.status === "streaming") {
                    if (!byteMessageAdded) {
                        setMessages(prevMessages => [
                            ...prevMessages,
                            { id: Date.now(), sender: 'Byte', text: initialMessage || data.chunk, isStreaming: true }
                        ]);
                        byteMessageAdded = true;
                    } else {
                        setMessages(prevMessages => {
                            const lastMessage = prevMessages[prevMessages.length - 1];
                            return [
                                ...prevMessages.slice(0, -1),
                                { ...lastMessage, text: lastMessage.text + data.chunk }
                            ];
                        });
                    }
                    setCurrentStatus(null);
                } else if (data.status === "completed") {
                    setMessages(prevMessages => {
                        const lastMessage = prevMessages[prevMessages.length - 1];
                        return [
                            ...prevMessages.slice(0, -1),
                            { ...lastMessage, text: data.response, isStreaming: false }
                        ];
                    });
                } else if (data.status === "ended") {
                    addMessage({
                        id: Date.now(),
                        text: "Thanks for answering the question. That's all for now. I'll be ending the interview now.",
                        sender: "Byte",
                    });
                    addMessage({
                        id: Date.now(),
                        text: "The interview has been completed. Thank you for your participation.",
                        sender: "System",
                    });
                    setTimeout(() => {
                        setIsEndDialogOpen(true);
                    }, 1000);
                } else {
                    setCurrentStatus(data.status);
                }
            } catch (parseError) {
                console.error('Error parsing JSON:', parseError);
            }
        }
    }, [setMessages, setCurrentStatus, navigate, sessionId, setIsEndDialogOpen, addMessage]);

    const handleSendMessage = useCallback(async () => {
        if (!inputMessage.trim()) return;
        const newMessage = { id: Date.now(), sender: 'You', text: inputMessage };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');
        setIsTyping(true);
        const isCodeChanged = code !== lastAnalyzedCode;
        setIsAnalyzingCode(isCodeChanged);

        setCurrentStatus(isCodeChanged ? "analyzing_code" : "thinking");

        try {
            const responseLines = await sendMessage(
                sessionId,
                inputMessage,
                code,
                timeRemaining
            );

            await processMessageStream(responseLines);
        } catch (error) {
            console.error('Error sending message:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred while processing your message.');
            }
        } finally {
            setIsTyping(false);
            setIsAnalyzingCode(false);
            setCurrentStatus(null);
            if (isCodeChanged) {
                setLastAnalyzedCode(code);
            }
        }
    }, [inputMessage, code, sessionId, timeRemaining, lastAnalyzedCode, processMessageStream]);

    const handleEndInterview = useCallback(() => {
        setIsEndDialogOpen(true);
    }, []);

    const confirmEndInterview = useCallback(async () => {
        try {
            const response = await endInterview(sessionId);
            logger.log("Interview ended", response.data);
            setIsEndDialogOpen(false);
            setIsInterviewEnded(true);
            setCurrentStatus("ended");
            addMessage({
                id: Date.now(),
                text: "The interview has ended. You will be redirected to the feedback page shortly.",
                sender: "System",
            });
            navigate(`/feedback?sessionId=${sessionId}`);
        } catch (error) {
            logger.error("Error ending interview:", error);
            addMessage({
                id: Date.now(),
                text: "Error ending interview. Please try again.",
                sender: "System",
            });
        }
    }, [sessionId, navigate, addMessage, logger]);

    const cancelEndInterview = useCallback(() => {
        setIsEndDialogOpen(false);
    }, []);

    const handleAnalyzeCode = useCallback(async () => {
        if (!code.trim()) return;
        setIsTyping(true);
        setIsAnalyzingCode(true);

        const userMessage = "Can you analyze this code and provide feedback?";
        addMessage({ id: Date.now(), text: userMessage, sender: "You" });

        setCurrentStatus("analyzing_code");

        try {
            const responseLines = await sendMessage(
                sessionId,
                userMessage,
                code,
                timeRemaining
            );

            await processMessageStream(responseLines, "");
        } catch (error) {
            console.error('Error analyzing code:', error);
            addMessage({
                id: Date.now(),
                text: "Error analyzing code. Please try again.",
                sender: "System",
            });
        } finally {
            setIsTyping(false);
            setIsAnalyzingCode(false);
            setCurrentStatus(null);
            setLastAnalyzedCode(code);
        }
    }, [code, sessionId, timeRemaining, addMessage, processMessageStream]);

    const handleTimeExpired = useCallback(async () => {
        setIsInterviewEnded(true);
        setCurrentStatus("ended");
        addMessage({
            id: Date.now(),
            text: "Time's up! The interview has ended. You will be redirected to the feedback page shortly.",
            sender: "System",
        });

        try {
            await endInterview(sessionId);
            logger.log("Interview ended due to time expiration");
            setTimeout(() => {
                navigate(`/feedback?sessionId=${sessionId}`);
            }, 2000);
        } catch (error) {
            logger.error("Error ending interview:", error);
            addMessage({
                id: Date.now(),
                text: "Error ending interview. Please try again or contact support.",
                sender: "System",
            });
        }
    }, [sessionId, navigate, addMessage, logger]);

    return {
        messages,
        inputMessage,
        setInputMessage,
        isTyping,
        isAnalyzingCode,
        timeRemaining,
        code,
        setCode,
        handleSendMessage,
        handleEndInterview,
        handleAnalyzeCode,
        sessionId,
        currentStatus,
        isEndDialogOpen,
        isInterviewEnded,
        confirmEndInterview,
        cancelEndInterview,
        handleTimeExpired,
        errorMessage,
        setErrorMessage,
    };
};

export default useInterviewLogic;

