import axios from "axios";
import { API_BASE_URL } from "../utils/constants";
import logger from "./logger";

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Dispatch an event that can be caught by a React component
      window.dispatchEvent(new CustomEvent('UNAUTHORIZED'));
    }
    return Promise.reject(error);
  }
);

export const getDashboardData = async () => {
  try {
    logger.log("Fetching dashboard data");
    const response = await api.get('/dashboard');
    logger.log(`Dashboard data fetched successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const createInterviewSession = (data) =>
  api.post("/interview/create-session", data);

export const getInterviewDetails = (sessionId) =>
  api.get(`/interview/details/${sessionId}`);

export const startInterview = (sessionId) =>
  api.post("/interview/start", { sessionId });

export const sendMessage = async (sessionId, message, code, timeRemaining) => {
  const response = await api.post("/interview/interact", {
    sessionId,
    message,
    code,
    timeRemaining,
    analyzeCode: code !== null
  }, {
    responseType: 'text',
    headers: {
      'Accept': 'text/event-stream'
    }
  });

  const lines = response.data.split('\n').filter(line => line.trim());
  return lines;
};

export const endInterview = (sessionId) =>
  api.post("/interview/end", { sessionId });

export const getInterviewFeedback = (sessionId) =>
  api.post("/interview/feedback", { sessionId });

export const submitUserFeedback = (sessionId, rating, comment) =>
  api.post("/interview/user-feedback", { sessionId, rating, comment });

export const submitContactForm = (formData) =>
  api.post("/contact", formData);

// Removed getServerTime function

export const clearAuthToken = () => {
  delete api.defaults.headers.common['Authorization'];
  localStorage.removeItem('authToken');
};

export const submitEnterpriseForm = async (formData) => {
  try {
    logger.log("Submitting enterprise form");
    const response = await api.post('/enterprise/submit-form', formData);
    logger.log(`Enterprise form submitted successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error submitting enterprise form:', error);
    throw error;
  }
};

export const subscribeToUpdates = async (email) => {
  try {
    logger.log("Subscribing to updates");
    const response = await api.post('/enterprise/subscribe', { email });
    logger.log(`Subscribed to updates successfully: ${JSON.stringify(response.data)}`);
    return response.data;
  } catch (error) {
    logger.error('Error subscribing to updates:', error);
    throw error;
  }
};

export default api;