import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Box, useTheme } from "@mui/material";
import CodingArea from './subcomponents/interviewpage/CodingArea';
import StatusArea from './subcomponents/interviewpage/StatusArea';
import ChatArea from './subcomponents/interviewpage/ChatArea';
import InterviewTips from './subcomponents/interviewpage/InterviewTips';
import EndInterviewDialog from './subcomponents/interviewpage/EndInterviewDialog';
import useInterviewLogic from '../hooks/useInterviewLogic';
import { useParams, useSearchParams } from 'react-router-dom';

const InterviewPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  console.log('Session ID:', sessionId);

  const theme = useTheme();
  const [showInterviewTips, setShowInterviewTips] = useState(true);
  const [programmingLanguage, setProgrammingLanguage] = useState('python');
  const messagesEndRef = useRef(null);
  const [interviewType, setInterviewType] = useState(null);

  const {
    messages,
    inputMessage,
    setInputMessage,
    isTyping,
    isAnalyzingCode,
    timeRemaining,
    code,
    setCode,
    handleSendMessage,
    handleEndInterview,
    confirmEndInterview,
    cancelEndInterview,
    handleAnalyzeCode,
    currentStatus,
    isEndDialogOpen,
    isInterviewEnded,
    handleTimeExpired,  // Add this new function
    errorMessage,
    setErrorMessage,
  } = useInterviewLogic(sessionId);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isTyping]);

  const handleCodePresenceChange = useCallback((isCodePresent) => {
    setShowInterviewTips(!isCodePresent);
  }, []);

  const handleLanguageChange = useCallback((newLanguage) => {
    setProgrammingLanguage(newLanguage);
  }, []);

  const memoizedHandleSendMessage = useCallback(() => {
    handleSendMessage();
  }, [handleSendMessage]);

  const memoizedSetInputMessage = useCallback((message) => {
    setInputMessage(message);
  }, [setInputMessage]);

  const memoizedHandleAnalyzeCode = useCallback(() => {
    handleAnalyzeCode();
  }, [handleAnalyzeCode]);

  const memoizedMessages = useMemo(() => messages, [messages]);

  return (
    <Box sx={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      position: 'relative',
      overflow: 'hidden',
    }}>
      <StatusArea
        timeRemaining={timeRemaining}
        handleEndInterview={handleEndInterview}
        currentStatus={currentStatus}
        handleTimeExpired={handleTimeExpired}  // Pass this to StatusArea
      />
      <Box sx={{
        display: "flex",
        flexGrow: 1,
        overflow: "hidden",
        boxShadow: `inset 0 0 20px ${theme.palette.background.paper}`,
      }}>
        <ChatArea
          messages={memoizedMessages}
          inputMessage={inputMessage}
          setInputMessage={memoizedSetInputMessage}
          handleSendMessage={memoizedHandleSendMessage}
          isTyping={isTyping}
          currentStatus={currentStatus}
          messagesEndRef={messagesEndRef}
          isInterviewEnded={isInterviewEnded}
          errorMessage={errorMessage}
        />
        <CodingArea
          code={code}
          setCode={setCode}
          onCodePresenceChange={handleCodePresenceChange}
          programmingLanguage={programmingLanguage}
          onLanguageChange={handleLanguageChange}
          onAnalyzeCode={memoizedHandleAnalyzeCode}
          isInterviewEnded={isInterviewEnded}
        />
      </Box>
      {showInterviewTips && <InterviewTips />}
      <EndInterviewDialog
        open={isEndDialogOpen}
        onClose={cancelEndInterview}
        onConfirm={confirmEndInterview}
      />
    </Box>
  );
};

export default React.memo(InterviewPage);