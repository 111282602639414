import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Box, Typography, Divider, TextField, IconButton, CircularProgress, useTheme } from "@mui/material";
import { Send } from "lucide-react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";

const MarkdownRenderer = React.memo(({ content }) => {
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <SyntaxHighlighter
                            style={atomDark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                        >
                            {String(children).replace(/\n$/, "")}
                        </SyntaxHighlighter>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                },
            }}
        >
            {content}
        </ReactMarkdown>
    );
});

const StatusUpdate = React.memo(({ status, theme }) => (
  <Box sx={{ display: "flex", alignItems: "center", mb: 2, height: 24 }}>
    <Typography
      variant="body2"
      sx={{
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        mr: 1,
      }}
    >
      {status === "analyzing_code" ? "Analyzing code" :
       status === "processing_response" ? "Processing response" :
       status === "filtering_response" ? "Filtering response" :
       status === "thinking" ? "Byte is thinking" :
       status === "ended" ? "The interview has ended." :
       "Processing"}
    </Typography>
    <CircularProgress size={20} color="secondary" />
  </Box>
));

const Message = React.memo(({ message, theme }) => {
  if (message.status) {
    return <StatusUpdate status={message.status} theme={theme} />;
  }

  return (
    <Box
      sx={{
        mb: 3,
        opacity: message.isNew ? 0 : 1,
        transform: message.isNew ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms',
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: "bold",
            color: message.sender === "You" ? theme.palette.primary.main : theme.palette.secondary.main,
            mr: 2,
            fontSize: "0.8rem",
          }}
        >
          {message.sender}
        </Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <Box sx={{
        color: theme.palette.text.primary,
        fontSize: "0.9rem",
        "& code": {
          backgroundColor: theme.palette.background.default,
          padding: "2px 4px",
          borderRadius: 1,
          fontSize: "0.8rem",
        },
        "& pre": {
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(1),
          borderRadius: 1,
          overflowX: "auto",
          fontSize: "0.8rem",
        },
        "& a": {
          color: theme.palette.primary.main,
        },
        "& table": {
          borderCollapse: "collapse",
          fontSize: "0.8rem",
          "& th, & td": {
            border: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(0.5, 1),
          },
        },
      }}>
        <MarkdownRenderer content={message.text} />
      </Box>
    </Box>
  );
});

const ChatArea = React.memo(({ 
  messages, 
  inputMessage, 
  setInputMessage, 
  handleSendMessage, 
  isTyping, 
  currentStatus, 
  messagesEndRef,
  errorMessage  // Add this new prop
}) => {
  const theme = useTheme();
  const containerRef = useRef(null);

  const handleInputChange = useMemo(() => (e) => setInputMessage(e.target.value), [setInputMessage]);
  const handleKeyPress = useMemo(() => (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  }, [handleSendMessage]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (containerRef.current) {
        const { scrollHeight, clientHeight } = containerRef.current;
        containerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    };

    scrollToBottom();
  }, [messages]);

  return (
    <Box sx={{
      width: "40%", 
      borderRight: `1px solid ${theme.palette.divider}`, 
      display: "flex", 
      flexDirection: "column", 
      bgcolor: "rgba(15, 15, 15, 0.8)"
    }}>
      <Box 
        ref={containerRef}
        sx={{ 
          flexGrow: 1, 
          overflow: "auto", 
          p: 2,
          scrollBehavior: "auto",
        }}
      >
        {messages.map((message) => (
          <Message key={message.id} message={message} theme={theme} />
        ))}
        {currentStatus && <StatusUpdate status={currentStatus} theme={theme} />}
        {errorMessage && (
          <Message
            message={{
              id: 'error',
              sender: 'System',
              text: errorMessage,
            }}
            theme={theme}
          />
        )}
        <div ref={messagesEndRef} />
      </Box>
      <Box sx={{
        bgcolor: "rgba(26, 26, 26, 0.8)",
        borderTop: `1px solid ${theme.palette.divider}`,
      }}>
        <TextField
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          variant="outlined"
          placeholder="Type your message..."
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={handleSendMessage}
                sx={{
                  color: theme.palette.primary.main,
                  "&:hover": {
                    bgcolor: "rgba(100, 255, 218, 0.1)",
                  },
                }}
              >
                <Send size={20} />
              </IconButton>
            ),
            style: { fontSize: "0.9rem" },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
              borderRadius: 0,
            }
          }}
        />
      </Box>
    </Box>
  );
});

export default ChatArea;