import { format } from "date-fns";

const logger = {
  log: (message, ...args) => {
    console.log(
      `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] ${message}`,
      ...args
    );
  },
  error: (message, ...args) => {
    console.error(
      `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] ERROR: ${message}`,
      ...args
    );
  },
  warn: (message, ...args) => {
    console.warn(
      `[${format(new Date(), "yyyy-MM-dd HH:mm:ss")}] WARN: ${message}`,
      ...args
    );
  },
};

export default logger;
