import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Container,
  Box,
  Button,
  useTheme,
  Fade,
  Grow,
  Paper,
  Divider,
  CircularProgress,
  TextField,
  Rating,
  Snackbar,
  LinearProgress,
} from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { motion } from "framer-motion";
import { Star, ThumbsUp, AlertTriangle } from "lucide-react";
import useLogging from "../hooks/useLogging";
import { getInterviewFeedback, submitUserFeedback } from "../services/api";
import Header from "./subcomponents/common/Header";
import AnimatedBackground from "./subcomponents/common/AnimatedBackground";
import { useNavigate, useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const FeedbackPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const navigate = useNavigate();
  const logger = useLogging("FeedbackPage");
  const theme = useTheme();

  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userRating, setUserRating] = useState(0);
  const [userComment, setUserComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    logger.log("FeedbackPage mounted, fetching feedback");
    if (sessionId) {
      fetchFeedback();
    } else {
      handleError("No session ID found in URL parameters");
    }
  }, [sessionId]);

  const fetchFeedback = useCallback(async () => {
    logger.log("Fetching feedback for session ID: ", sessionId);
    if (!sessionId) {
      handleError("No session ID found in URL parameters");
      return;
    }

    try {
      const response = await getInterviewFeedback(sessionId);
      if (response && response.data) {
        setFeedback(response.data);
        logger.log("Feedback received:", response.data);
      } else {
        handleError("Received empty feedback from the server");
      }
    } catch (error) {
      handleError("Failed to fetch feedback", error);
    } finally {
      setIsLoading(false);
    }
  }, [sessionId, logger]);

  const handleError = (message, error = null) => {
    logger.error(message, error);
    setError(message);
    setIsLoading(false);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSubmitFeedback = async () => {
    if (!sessionId) {
      setSnackbarMessage("Error: No session ID found. Please try again.");
      setSnackbarOpen(true);
      return;
    }

    setIsSubmitting(true);
    try {
      await submitUserFeedback(sessionId, userRating, userComment);
      setSnackbarMessage("Thank you for your feedback!");
      setSnackbarOpen(true);
      setTimeout(() => navigate("/dashboard"), 3000);
    } catch (error) {
      logger.error("Error submitting user feedback:", error);
      setSnackbarMessage("Failed to submit feedback. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const MarkdownRenderer = ({ content }) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              style={atomDark}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const FeedbackSection = ({ title, score, explanation }) => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <LinearProgress
          variant="determinate"
          value={score * 10}
          sx={{
            flexGrow: 1,
            mr: 2,
            height: 10,
            borderRadius: 5,
            backgroundColor: theme.palette.grey[800],
            '& .MuiLinearProgress-bar': {
              borderRadius: 5,
              backgroundColor: theme.palette.primary.main,
            },
          }}
        />
        <Typography variant="body2" sx={{ minWidth: 35 }}>
          {score}/10
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {explanation}
      </Typography>
    </Box>
  );

  const ListSection = ({ title, items, icon }) => (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}>
        {React.createElement(icon, { size: 20, style: { marginRight: '8px' } })} {title}
      </Typography>
      <ul style={{ paddingLeft: '20px', margin: 0 }}>
        {items.map((item, index) => (
          <li key={index}>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    </Box>
  );

  const renderFeedbackContent = () => {
    if (isLoading) {
      return (
        <Fade in={true} timeout={800}>
          <Box sx={{ textAlign: "center", mt: 8, mb: 4 }}>
            <CircularProgress size={60} sx={{ mb: 4 }} />
            <Typography variant="h4" gutterBottom>
              Generating Feedback
            </Typography>
            <Typography variant="body1" color="text.secondary">
              This might take a while. You can also check the results from the dashboard after it's ready.
            </Typography>
          </Box>
        </Fade>
      );
    }

    if (error) {
      return (
        <Fade in={true} timeout={800}>
          <Box sx={{ textAlign: "center", mt: 8 }}>
            <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main", mb: 4 }} />
            <Typography variant="h4" gutterBottom>
              Oops! Something went wrong
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {error}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/dashboard")}
              sx={{ mt: 4 }}
            >
              Return to Home
            </Button>
          </Box>
        </Fade>
      );
    }

    if (!feedback || Object.keys(feedback).length === 0) {
      return (
        <Fade in={true} timeout={800}>
          <Box sx={{ textAlign: "center", mt: 8 }}>
            <ErrorOutlineIcon sx={{ fontSize: 60, color: "warning.main", mb: 4 }} />
            <Typography variant="h4" gutterBottom>
              No Feedback Available
            </Typography>
            <Typography variant="body1" color="text.secondary">
              We couldn't find any feedback for this interview session. This might be due to an incomplete interview or a processing error. Please try again later.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/dashboard")}
              sx={{ mt: 4 }}
            >
              Return to Home
            </Button>
          </Box>
        </Fade>
      );
    }

    return (
      <Fade in={true} timeout={800}>
        <Box>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{
                fontWeight: "bold",
                mb: 4,
                textAlign: "center",
                fontSize: { xs: "2.5rem", sm: "3.5rem", md: "4rem" },
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textShadow: "0 0 20px rgba(100, 255, 218, 0.2)",
              }}
            >
              Interview Feedback
            </Typography>
          </motion.div>
          <Fade in={true} timeout={800}>
            <Box>
              <Grow in={true} timeout={1000}>
                <Paper
                  elevation={3}
                  sx={{
                    bgcolor: "rgba(26, 32, 44, 0.7)",
                    backdropFilter: "blur(10px)",
                    p: 4,
                    borderRadius: 2,
                    mb: 4,
                    overflow: "hidden",
                    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                    border: "1px solid rgba(255, 255, 255, 0.18)",
                  }}
                >
                  <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, textAlign: 'center', mb: 3 }}>
                    {feedback.recommendation}
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 4, color: theme.palette.text.secondary }}>
                    {feedback.summary}
                  </Typography>
                  <Divider sx={{ mb: 4 }} />
                  <FeedbackSection title="Code Implementation" score={feedback.codeFeedback.score} explanation={feedback.codeFeedback.explanation} />
                  <FeedbackSection title="Problem Solving" score={feedback.problemSolving.score} explanation={feedback.problemSolving.explanation} />
                  <FeedbackSection title="Technical Skills" score={feedback.technical.score} explanation={feedback.technical.explanation} />
                  <Divider sx={{ mb: 4 }} />
                  <ListSection title="Strengths" items={feedback.strengths} icon={ThumbsUp} />
                  <ListSection title="Areas for Improvement" items={feedback.areasOfImprovement} icon={AlertTriangle} />
                </Paper>
              </Grow>
              <Fade in={true} timeout={1200}>
                <Paper
                  elevation={3}
                  sx={{
                    bgcolor: "rgba(26, 32, 44, 0.7)",
                    backdropFilter: "blur(10px)",
                    p: 4,
                    borderRadius: 2,
                    mt: 4,
                    mb: 4,
                    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
                    border: "1px solid rgba(255, 255, 255, 0.18)",
                  }}
                >
                  <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: theme.palette.primary.main }}>
                    Your Feedback
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, color: theme.palette.text.secondary }}>
                    We'd love to hear about your interview experience. Your feedback helps us improve!
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                      How would you rate your overall experience?
                    </Typography>
                    <Rating
                      value={userRating}
                      onChange={(event, newValue) => setUserRating(newValue)}
                      size="large"
                      icon={<Star style={{ color: theme.palette.primary.main }} />}
                      emptyIcon={<Star style={{ opacity: 0.55 }} />}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    placeholder="Share your thoughts on the interview process, interactions, or any suggestions for improvement..."
                    value={userComment}
                    onChange={(e) => setUserComment(e.target.value)}
                    sx={{
                      mb: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                        "&:hover fieldset": {
                          borderColor: theme.palette.primary.light,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme.palette.primary.main,
                        },
                      },
                    }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 3 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitFeedback}
                      disabled={isSubmitting}
                      sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: 1,
                        fontWeight: "bold",
                        background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        boxShadow: `0 4px 14px 0 ${theme.palette.primary.main}40`,
                        "&:hover": {
                          background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.dark})`,
                          boxShadow: `0 6px 20px 0 ${theme.palette.primary.main}60`,
                        },
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Submit Feedback"}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/dashboard")}
                      sx={{
                        px: 4,
                        py: 1.5,
                        borderRadius: 1,
                        fontWeight: "bold",
                        color: theme.palette.grey[300],
                        borderColor: theme.palette.grey[700],
                        '&:hover': {
                          backgroundColor: theme.palette.grey[800],
                          borderColor: theme.palette.grey[600],
                        },
                      }}
                    >
                      Back to Home
                    </Button>
                  </Box>
                </Paper>
              </Fade>
            </Box>
          </Fade>
        </Box>
      </Fade>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        bgcolor: "background.default",
        color: "text.primary",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <AnimatedBackground />
      <Header />
      <Container maxWidth="md" sx={{ flexGrow: 1, py: 4, position: "relative", zIndex: 1 }}>
        {renderFeedbackContent()}
      </Container>
      <Fade in={true} timeout={1400}>
        <Box sx={{ mt: 6, mb: 4, textAlign: "center" }}>
          <Divider sx={{ mb: 4 }} />
          <Typography variant="body2" color="text.secondary">
            Thank you for using Dobr.ai! We're constantly improving to provide you with the best interview preparation experience.
          </Typography>
        </Box>
      </Fade>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default FeedbackPage;