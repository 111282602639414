import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Box, Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useLogging from "../hooks/useLogging";
import Header from "./subcomponents/common/Header";
import { getDashboardData } from '../services/api';
import { useAuth } from "../contexts/AuthContext";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} size={120} thickness={4} sx={{ color: 'grey.300' }} />
      <CircularProgress
        variant="determinate"
        {...props}
        size={120}
        thickness={4}
        sx={{
          color: 'grey.800',
          position: 'absolute',
          left: 0,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h4" component="div" color="text.primary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function DashboardPage() {
  const { user, loading, checkUser } = useAuth();
  const navigate = useNavigate();
  const logger = useLogging("DashboardPage");
  const [stats, setStats] = useState({
    total_interviews: 0,
    completed_interviews: 0,
    success_percentage: 0
  });
  const [pastInterviews, setPastInterviews] = useState([]);
  const [pastInterviewsLoading, setPastInterviewsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataFetchedRef = useRef(false);

  const fetchPastInterviews = useCallback(async () => {
    logger.log("fetchPastInterviews called");
    try {
      logger.log("Fetching past interviews data...");
      const data = await getDashboardData();
      logger.log("Past interviews data received:", data);
      
      // Update this part to match the new data structure
      setStats(data.recentInterviews.stats || {
        total_interviews: 0,
        completed_interviews: 0,
        success_percentage: 0
      });
      setPastInterviews(data.recentInterviews.recentInterviews || []);
      setError(null);
    } catch (error) {
      logger.error("Error fetching past interviews data:", error);
      setError("Failed to load past interviews. Please try again later.");
    } finally {
      setPastInterviewsLoading(false);
    }
  }, [logger]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    logger.log("DashboardPage useEffect triggered", { user, loading });

    if (!loading && user) {
      dataFetchedRef.current = true;
      fetchPastInterviews();
    } else if (!loading && !user) {
      checkUser().then((checkedUser) => {
        if (!checkedUser) {
          navigate('/', { replace: true });
        }
      });
    }
  }, [loading, user, navigate, fetchPastInterviews, logger, checkUser]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  const handleStartInterview = (type) => {
    navigate("/setup", { state: { interviewType: type } });
  };

  const handleViewFeedback = (interviewId) => {
    navigate(`/feedback?sessionId=${interviewId}`);
  };

  const interviewTypes = [
    { title: "Coding Interview", description: "Practice interviews for algorithmic problem-solving and data structures.", comingSoon: false },
    { title: "Machine Coding", description: "Practice interviews for low-level system components.", comingSoon: true },
    { title: "System Design", description: "Practice interviews for large-scale distributed systems.", comingSoon: true },
  ];

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column", bgcolor: "background.default", color: "text.primary" }}>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: 'text.primary', mb: 4 }}>
          Interview Practice
        </Typography>

        {/* Interview Types Section */}
        <Grid container spacing={3} sx={{ mb: 6 }}>
          {interviewTypes.map((type, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box sx={{
                height: '100%',
                bgcolor: '#f5f5f5', // Light background for cards
                color: '#000000', // Dark text for cards
                border: '1px solid #E0E0E0',
                display: 'flex',
                flexDirection: 'column',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
                },
                borderRadius: 0,
              }}>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', p: 3 }}>
                  <Typography variant="h6" component="h2" gutterBottom sx={{ mb: 2, fontWeight: 'bold', color: '#000000' }}>
                    {type.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 3, color: '#424242', flexGrow: 1 }}>
                    {type.description}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => type.comingSoon ? null : handleStartInterview(type.title.split(' ')[0])}
                    disabled={type.comingSoon}
                    sx={{
                      color: type.comingSoon ? '#757575' : '#ffffff',
                      borderColor: type.comingSoon ? '#757575' : '#14cba8',
                      bgcolor: type.comingSoon ? 'transparent' : '#14cba8',
                      '&:hover': {
                        borderColor: type.comingSoon ? '#757575' : '#0fa88d',
                        bgcolor: type.comingSoon ? 'transparent' : '#0fa88d',
                        borderColor: '#14cba8', // Maintain consistent border color on hover
                      },
                      '&.Mui-disabled': {
                        color: '#757575', // Ensure the text color is visible when disabled
                        borderColor: '#757575',
                        opacity: 0.7, // Slightly reduce opacity to indicate it's disabled
                      },
                      alignSelf: 'flex-start',
                      mt: 'auto',
                      textTransform: 'none',
                      borderRadius: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    {type.comingSoon ? 'Coming Soon' : 'Start Interview'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* Interview Stats Section */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: 'text.primary', mb: 3 }}>
            Interview Statistics
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                height: '100%', 
                bgcolor: 'background.paper', 
                border: '1px solid #E0E0E0',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Total Interviews
                </Typography>
                <Typography variant="h2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                  {stats.total_interviews}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                height: '100%', 
                bgcolor: 'background.paper', 
                border: '1px solid #E0E0E0',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Completed Interviews
                </Typography>
                <Typography variant="h2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                  {stats.completed_interviews}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ 
                height: '100%', 
                bgcolor: 'background.paper', 
                border: '1px solid #E0E0E0',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  Success Rate
                </Typography>
                <CircularProgressWithLabel value={stats.success_percentage} />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Past Interviews Section */}
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ color: 'text.primary', mb: 3 }}>
            Past Interviews
          </Typography>
          {error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <TableContainer component={Paper} sx={{ 
              bgcolor: 'background.paper', 
              boxShadow: 'none',
              border: '1px solid #E0E0E0',
              borderRadius: 0,
              '& .MuiTableCell-root': { textAlign: 'center' }
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: 'text.primary' }}>Category</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'text.primary' }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'text.primary' }}>Recommendation</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'text.primary' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pastInterviewsLoading ? (
                    [...Array(3)].map((_, index) => (
                      <TableRow key={index}>
                        <TableCell><Skeleton variant="text" /></TableCell>
                        <TableCell><Skeleton variant="text" /></TableCell>
                        <TableCell><Skeleton variant="text" /></TableCell>
                        <TableCell><Skeleton variant="rectangular" width={60} height={30} /></TableCell>
                      </TableRow>
                    ))
                  ) : pastInterviews.length > 0 ? (
                    pastInterviews.map((interview) => (
                      <TableRow key={interview.id}>
                        <TableCell>{interview.type}</TableCell>
                        <TableCell>{new Date(interview.date).toLocaleDateString()}</TableCell>
                        <TableCell>{interview.recommendation}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => handleViewFeedback(interview.id)}
                            sx={{
                              color: 'text.primary',
                              borderColor: 'text.primary',
                              '&:hover': {
                                bgcolor: 'action.hover',
                              },
                              textTransform: 'none',
                              borderRadius: 0,
                            }}
                          >
                            View Feedback
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>No past interviews found.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default React.memo(DashboardPage);