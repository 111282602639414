import React from 'react';
import { Typography, Button, Container, Box, useTheme, Fade, Grow } from '@mui/material';
import { ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

const HeroSection = ({ handleStart, scrollToMore }) => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ position: "relative" }}>
      <Box
        sx={{
          minHeight: "calc(100vh - 80px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: { xs: 8, sm: 12, md: 16 }, // Adjust vertical padding for different screen sizes
        }}
      >
        <Grow in={true} timeout={1000}>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              fontSize: { xs: "2.5rem", sm: "3.5rem", md: "5rem" },
              fontWeight: "bold",
              mb: 10,
              letterSpacing: "-0.05em",
              lineHeight: 1.2,
              background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Beyond Endless
            <br />
            Leetcode Grind.
          </Typography>
        </Grow>
        <Fade in={true} timeout={2000}>
          <Typography
            variant="h5"
            component="p"
            sx={{
              mb: 10,
              maxWidth: "800px",
              lineHeight: 1.6,
              color: 'rgba(255, 255, 255, 0.6)', // White color with 80% opacity
            }}
          >
            Ace your coding interviews with real world interview simulation.
          </Typography>
        </Fade>
        <Fade in={true} timeout={2500}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons vertically on extra small screens
            justifyContent: 'center', // Center align buttons
            gap: 2,
            width: '100%', // Ensure the box takes full width
            px: 2, // Add horizontal padding
          }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleStart}
              sx={{
                px: { xs: 4, sm: 6 }, // Adjust horizontal padding
                py: 2,
                fontSize: { xs: "1rem", sm: "1.25rem" }, // Adjust font size
                fontWeight: "bold",
                borderRadius: "12px",
                textTransform: "none",
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                boxShadow: `0 4px 14px 0 ${theme.palette.primary.main}40`,
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: `0 6px 20px 0 ${theme.palette.primary.main}60`,
                  bgcolor: theme.palette.primary.dark,
                },
                transition: "all 0.3s ease-in-out",
                width: { xs: '100%', sm: 'auto' }, // Full width on mobile, auto on larger screens
                minWidth: { sm: '200px' }, // Minimum width on larger screens
              }}
            >
              GET STARTED
            </Button>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              component={Link}
              to="/enterprise/get-started"
              sx={{
                px: { xs: 4, sm: 6 }, // Adjust horizontal padding
                py: 2,
                fontSize: { xs: "1rem", sm: "1.25rem" }, // Adjust font size
                fontWeight: "bold",
                borderRadius: "12px",
                textTransform: "none",
                borderColor: theme.palette.common.white,
                color: theme.palette.common.white,
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: `0 6px 20px 0 ${theme.palette.common.white}40`,
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                },
                transition: "all 0.3s ease-in-out",
                width: { xs: '100%', sm: 'auto' }, // Full width on mobile, auto on larger screens
                minWidth: { sm: '200px' }, // Minimum width on larger screens
              }}
            >
              FOR COMPANIES
            </Button>
          </Box>
        </Fade>
      </Box>
    </Container>
  );
};

export default HeroSection;