const useLogging = (componentName) => {
  const log = (message, ...args) => {
    console.log(`[${componentName}]`, message, ...args);
  };

  const error = (message, ...args) => {
    console.error(`[${componentName}]`, message, ...args);
  };

  const warn = (message, ...args) => {
    console.warn(`[${componentName}]`, message, ...args);
  };

  return { log, error, warn };
};

export default useLogging;